import axios from 'axios';
import { API } from '../_helpers/Constants';


export function TokenHelper(callback) {

    let token = localStorage.getItem('_token');
    let username = localStorage.getItem('_username');
    let client = JSON.parse(localStorage.getItem('_profile'));
    
    if(token === null || username === null || client === null)
        return callback(false);

    // console.log(client);
    let clientID = client.subdomain_id;

    var urls = API + "token/checkToken?username="+ username +"&token="+ token +"&subdomain_id=" + clientID;
    // console.log("check token server : " ,urls);
    axios({
      method: 'get',
        url: urls,
    }).then(function (response) {
        if(response.data.status === 200){        
            return callback(true);
        }else{
            clearLocalStorage();
            return callback(false);
        }
    }).catch(function (error) {
        clearLocalStorage();
        return callback(false);
    });
}

function clearLocalStorage(){
    localStorage.removeItem('_token');
    localStorage.removeItem('_username');
    localStorage.removeItem('_profile');
}

export function checkTokenClient(){

}


export default TokenHelper;

import React,{ Component } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import  Vertical_1 from '../../_components/Vertical_1';
import { DateHelper } from '../../_helpers/DateHelper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import  Header from '../../_components/Header';
import  Footer from '../../_components/Footer';

export default class Index extends Component {

    constructor(props){
        super(props);
        this.state = {
            id : '',
            data : [],
            activeTab : props.activeTab || 1,
            dateFrom : DateHelper(),
            dateTo : DateHelper(),
            onlineNews : "",
        }
        this.handleSelect = this.handleSelect.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        //call child function with ref
        this.printRef = React.createRef();
        this.onlineRef = React.createRef();
        this.tvRef = React.createRef();
        this.radioRef = React.createRef();
    }

    handleSelect(selectedTab) {
        this.setState({
          activeTab: selectedTab
        });
    }

    handleChange(event){
        this.setState({
            [event.target.name] : event.target.value
        });
    }

    handleSubmit(event){
        if(this.state.dateFrom == '' || this.state.dateTo == '' )
            return   

        var self = this;
        this.setState({
            isPrintProcess : true,
            isOnlineProcess : true,
            isTvProcess : true,
        }, () =>{
            self.onlineRef.current.forceUpdateComponent(self.state.dateFrom, self.state.dateTo );
            self.printRef.current.forceUpdateComponent(self.state.dateFrom, self.state.dateTo );
            self.tvRef.current.forceUpdateComponent(self.state.dateFrom, self.state.dateTo );
        })
       
    }

    
     
    render(){        
        return (

            <div>
                <Header></Header>

                <br></br>
                    <div className="blog-area section-padding-0-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="contact-title" style={{float:'left'}}>
                                    <h2>Index berita</h2>
                                </div>

                                <div  className="form-inline float-right">
                                    <input type="date" className="form-control my-1 mr-sm-2" name="dateFrom" onChange={this.handleChange } value={this.state.dateFrom} placeholder="Date From" />
                                    <input type="date" className="form-control my-1 mr-sm-2" name='dateTo' onChange={this.handleChange } value={this.state.dateTo} placeholder="Date to" />
                                    <button type="submit" onClick={this.handleSubmit} className="btn  btn-success my-1 mr-sm-2">
                                        {this.state.isLoading ? <FontAwesomeIcon style={{marginRight:5}} icon={faSpinner} spin/> : '' } Submit
                                    </button>
                                </div>

                            </div>
                        </div>
                        <div className='clearfix'></div>
                        <br></br>
                        <div className="row">
                            <div className='col-12 col-md-12'>
                               
                                <Tabs activeKey={this.state.activeTab} onSelect={this.handleSelect}>
                                    <Tab eventKey={1} title="Berita Online ">
                                        <br></br>
                                        <Vertical_1 total="99" showBig1="false" type="online" category="" dateFrom={this.state.dateFrom}  dateTo={this.state.dateTo} ref={this.onlineRef} ></Vertical_1>
                                    </Tab>
                                    <Tab eventKey={2} title="Berita Cetak">
                                        <br></br>
                                        <Vertical_1 total="99" showBig1="false" type="printed" category="" dateFrom={this.state.dateFrom}  dateTo={this.state.dateTo} ref={this.printRef}></Vertical_1>
                                    </Tab>
                                    <Tab eventKey={3} title="Berita TV">
                                        <br></br>
                                        <Vertical_1 total="99" showBig1="false" type="tv" category="" dateFrom={this.state.dateFrom}  dateTo={this.state.dateTo} ref={this.tvRef}></Vertical_1>
                                    </Tab>
                                    <Tab eventKey={4} title="Berita Radio">
                                        <br></br>
                                    </Tab>
                                </Tabs>

                            </div>
                            
                        </div>
                    </div>
                </div>


                <Footer></Footer>
            </div>
        ) 
    }
  
}


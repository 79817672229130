import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Newsticker from 'react-newsticker';
import { DateHelper } from '../_helpers/DateHelper';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import axios from 'axios';
import { API, CLIENT } from '../_helpers/Constants';

export default class Ticker_1 extends Component {
  constructor(props){
    super(props);
    this.state = {
        data : [],
        client : JSON.parse(localStorage.getItem("_profile")),
        dateTo : props.dateTo || DateHelper(),
        dateFrom : props.dateFrom || '2019-10-01',  //DateHelper(),
    }
  }

  componentDidMount(){
    this.getData();
  }
  getData(){
    this.setState({isLoading : true});
    var self = this;
    var urls =  API + "news/getall?username="+ localStorage.getItem('_username')
        + "&token="+ localStorage.getItem('_token') 
        + "&subdomain_id=" + CLIENT 
        + "&date_from=" + this.state.dateFrom
        + "&date_to=" + this.state.dateTo
        + "&limit=20" 
    // console.log(urls);
    axios({
        method: 'get',
        url: urls,
    }).then(function (response) {
        if(response.data.status === 200){
            // self.setState({ result : response.data.data});
            let dataArr = [];    
            response.data.data.map((item, i) => {
              var news = <Link to={"/news/" + item.berita_id}> <span style={{color:self.state.client.subdomain_color}} > {item.media_nama}</span> -  {item.berita_judul} <small>{item.berita_tanggal_tampil}</small> </Link>
              dataArr.push(news);
            });
            self.setState({ data : dataArr});
        }
        self.setState({isLoading : false});
    }).catch(function (error) {
        self.setState({isLoading : false});
    });
}


 
  render(){

    // const news = [
    //     <Link to="/news/223">Welcome to paradise - Guns and Rosses</Link>,
    //     'Nice to meet you.',
    //     'Happy hour :)'
    //   ];

    return (
        <div className="breaking-news-area d-flex align-items-center">
            <div className="news-title"  style={{backgroundColor:this.state.client.subdomain_color}}>
                <p>Breaking News</p>
            </div>
            <div className="news-ticker" style={{width:'100%'}}>
                {this.state.isLoading ? <FontAwesomeIcon style={{marginRight:5,alignSelf:"center"}} icon={faSpinner} spin/> : <Newsticker news={this.state.data} /> }
                
            </div>
        
        </div>
       
        )
  }
 
}






